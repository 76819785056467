/**
 * @flow
 */

const logger = {
  debug: (_) => {},
  info: (_) => {},
  warn: (_) => {},
  error: (_) => {},
  log: (_, __) => {},
};

window.console = logger;
